import { useState, useEffect } from "react";

import { usePostData } from "../../../controllers/fetchData";

export default function ProductUpdatePrice({ value, valueNetto, id, language, type }) {
  const { response, idle, error, makePostRequest } = usePostData();

  const [isEditing, setIsEditing] = useState(false);
  const [price, setPrice] = useState(value);
  const [priceNetto, setPriceNetto] = useState(valueNetto);

  useEffect(() => {
    setPrice(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updatePrice = () => {
    const brutto = parseFloat(price);
    const netto = parseFloat(priceNetto);
    const priceType = type;
    const productId = id;
    const data = { productId, priceType, brutto, netto };
    makePostRequest("admin/products/update-price", data);
    console.log(data);
    setIsEditing(false);
  };

  return (
    <>
      {isEditing ? (
        <div className="flex flex-col gap-4">
          <label htmlFor="price">Brutto</label>
          <input
            className="form__input"
            type="number"
            name="price"
            id="price"
            defaultValue={price}
            onChange={(e) => {
              setPrice(e.target.value);
            }}
          />
          <label htmlFor="price">Netto</label>
          <input
            className="form__input"
            type="number"
            name="price"
            id="price"
            defaultValue={priceNetto}
            onChange={(e) => {
              setPriceNetto(e.target.value);
            }}
          />
          <div>
            <span
              className="cursor-pointer mr-3"
              onClick={() => {
                updatePrice();
              }}
            >
              Zapisz
            </span>
            <span
              className="cursor-pointer"
              onClick={() => setIsEditing(false)}
            >
              Anuluj
            </span>
          </div>
        </div>
      ) : (
        <span
          className={`cursor-pointer ${language}`}
          onClick={() => setIsEditing(true)}
        >
          {price}
        </span>
      )}
      {/* {error && <p className="error">{error}</p>} */}
    </>
  );
}
