import { useState, useEffect } from "react";

import { sortOrdersId, sortOrdersDate, sortOrdersEmail, sortOrdersValue, sortOrdersPayment, sortOrdersShippment } from "../../controllers/Sorting";
import AdminOrdersTableAlt from "./AdminOrdersTableAlt";
import Alert from "../Alert";

import FilteringModule from "../FilteringModule";

import { FilteredOrders } from "../FilteredOrders";
import AdminDashboardModule from "./AdminDashboardModule";

export default function AdminOrders({ types }) {
  const [filter, setFilter] = useState();
  const [filteredOrders, setFilteredOrders] = useState();
  const [isPennding, setIsPennding] = useState(true);
  const [err, setErr] = useState(null);

  FilteredOrders(filter, setFilteredOrders, setIsPennding, setErr, types);

  const [orders, setOrders] = useState([]);
  const [sortedOrders, setSortedOrders] = useState();
  const [direction, setDirection] = useState("descending");

  const [alertContent, setAlertContent] = useState();
  const [alertIsOn, setAlertIsOn] = useState();

  const [searchValue, setSearchValue] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [paymentSearch, setPaymentSearch] = useState("");
  const [shippmentSearch, setShippmentSearch] = useState("");
  const [ordersType, setOrdersType] = useState("all");

  // console.log(filter)
  // console.log(filteredOrders)

  useEffect(() => {
    setOrders(filteredOrders);
  }, [filteredOrders]);

  useEffect(() => {
    if (orders) {
      setSortedOrders(sortOrdersId(orders, direction));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders, direction]);

  const sortAction = (property) => {
    let dir;
    if (direction === "descending") {
      dir = "ascending";
    } else if (direction === "ascending") {
      dir = "descending";
    }
    console.log(property);
    console.log(direction);
    switch (property) {
      case "id":
        setSortedOrders(sortOrdersId(orders, dir));
        setDirection(dir);
        break;
      case "email":
        setSortedOrders(sortOrdersEmail(orders, dir));
        setDirection(dir);
        break;
      case "date":
        setSortedOrders(sortOrdersDate(orders, dir));
        setDirection(dir);
        break;
      case "value":
        setSortedOrders(sortOrdersValue(orders, dir));
        setDirection(dir);
        break;
      case "payment":
        setSortedOrders(sortOrdersPayment(orders, dir));
        setDirection(dir);
        break;
      case "shippment":
        setSortedOrders(sortOrdersShippment(orders, dir));
        setDirection(dir);
        break;
      default:
        return;
    }
  };

  const clearFilter = () => {
    setFromDate("");
    setToDate("");
    setSearchValue("");
    setPaymentSearch("");
    setShippmentSearch("");
    setOrdersType("all");
    setSortedOrders(sortOrdersId(orders, direction));
  };

  const showAlert = (alert, state) => {
    setAlertContent(alert);
    setAlertIsOn(state);
  };

  return (
    <>
      <Alert
        alert={alertContent}
        alertIsOn={alertIsOn}
        showAlert={showAlert}
      />
      <FilteringModule setFilter={setFilter} />
      {sortedOrders ? (
        <>
          <AdminDashboardModule sortedOrders={sortedOrders} />
        </>
      ) : (
        <></>
      )}
      <AdminOrdersTableAlt
        sortedOrders={sortedOrders}
        isPennding={isPennding}
        err={err}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        setFromDate={setFromDate}
        setToDate={setToDate}
        sortAction={sortAction}
        clearFilter={clearFilter}
        toDate={toDate}
        fromDate={fromDate}
        setOrdersType={setOrdersType}
        setPaymentSearch={setPaymentSearch}
        setShippmentSearch={setShippmentSearch}
        ordersType={ordersType}
        paymentSearch={paymentSearch}
        shippmentSearch={shippmentSearch}
      />
    </>
  );
}
